@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
    --brand: #A90007;
    --dark: #092032;
    --body: #516171;
    --border: rgba(0,0,0,0.08);
    --shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    --topheader:#DCDCDC;
    --footercolor:#082032;

    
}

body {
     /* font-family: "Barlow", sans-serif !important; */
    color: #516171;
    color: var(--body);
    line-height: 1.7;
}

h1,h2,h3,h4,h5,h6,
.display-1,.display-2,.display-3,.display-4 {
    font-weight: 700;
color: #092032;
color: var(--dark) ;
}

.bg-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* img {
    width: 100%;
} */

a {
    color: #092032;
    color: var(--dark);
    transition: all 0.4s ease;
    font-weight: 500;
}

a:hover {
    color: #A90007 !important;
    color: var(--brand) !important;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.text-brand {
    color: #A90007 !important;
    color: var(--brand) !important;
}


.hero-slider .owl-prev,
.hero-slider .owl-next{
    background-color: rgba(255, 255, 255, 0.3) !important;
    width: 60px !important;
    height: 60px !important;
    display: block;
    display: grid;
    place-items: center;
    color: #fff !important;
    border-radius: 100px;
    line-height: 0;
    border-radius: 100px !important;
    position: absolute;
    top: 50%;
    font-weight: 600 !important;
    font-size: 12px !important;
    transition: all 0.4s ease;
    margin-top: -30px !important;
}

.owl-prev {
    left: 0;
}
.owl-next {
    right: 0;
}

.hero-slider .owl-prev:hover,
.hero-slider .owl-next:hover {
    background-color: #A90007 !important;
    background-color: var(--brand) !important; 
}

.owl-dot.active span{
    background-color: #A90007 !important;
    background-color: var(--brand) !important;
}


 /* slide */
  /* .slide {
     min-height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     position: relative;
} */

.slide1 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/static/media/bg_banner1.1ffbdd4c.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/*.slide2 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./Assets/Images/bg_banner1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide3 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(./Assets/Images/project2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide .display-3 {
    text-transform: uppercase;
    color: #fff;
} */

/* navbar */
.top-nav {
    /* background-color: var(--brand); */
    color: #fff;
    /* padding-top: 5px;
    padding-bottom: 5px; */
     margin-top: -12vh !important;
    /* width: 100% !important; */
    height: 41px !important;
    
   
}

.top-nav .email1 {
    /* background-color: orange !important; */
     width: 51.6% !important;
     float: left;
     margin-left: 6.5% !important;
    margin-top: .4% !important;
    height: 30px !important;
      /* margin-top: .4%; 
      margin-bottom: .3%; */
}

.top-nav .social {
    /* background-color: green !important; */
     width: 33% !important;
     float: left !important;
     text-align: right !important;
     /* padding-top: 13px!important;
      margin-top: -7px !important; */
     height: 30px !important;
   
     margin-top: .4%;
     margin-bottom: .3%;
   
}

.top-nav .cont {

     width: '100%' !important;
     background-color: aqua !important;
}

.top-nav p {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
    vertical-align: middle;
}

.top-nav span,
.top-nav i {
    vertical-align: middle;
}

.navbar {
    /* box-shadow: var(--shadow) !important; */
  background-color:#DCDCDC;
  background-color:var(--topheader);
      
}

.navbar img { position: absolute !important;  }

.social-icons a {
    width: 28px;
    height: 28px;
    display: inline-flex;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    
}
.social-icons a
{
  margin-right: 5px !important;
  
}
.social-icons a:hover {
    background-color: #fff;
    color: #A90007;
    color: var(--brand);
}
.conditions-section{
    margin: 20px 0;
}
.conditions-section a{
   color: #fff;
   margin: 0 10px;
}


.navbar .content {
    padding-left:70px !important;
    padding-right: 140px !important;
    padding-top: 20px !important;
    min-height: 80px !important;
    
}


.navbar .navbar-nav .nav-link {
    color: #092032 !important;
    color: var(--dark) !important;
     background-color: transparent !important;
     font-size: 20px !important;
     font-weight: 600 !important;
     min-width: 45px !important;
     margin-top: 3px !important;
   
}

.navbar .navbar-nav .nav-link:hover {
    color: #A90007 !important;
    color: var(--brand) !important;
}

.navbar .navbar-nav .nav-link.active {
    color: #A90007 !important;
    color: var(--brand) !important;
}

.navbar-brand {
    font-size: 28px;
    font-weight: 700;
}

.navbar-brand .dot {
    color: #A90007;
    color: var(--brand);
}

.btn {
    padding: 8px 26px;
}

.btn-brand {
    border-color: #A90007 !important;
    border-color: var(--brand) !important;
    background-color: #A90007 !important;
    background-color: var(--brand) !important;
    color: #fff !important;
}

.btn-brand:hover {
    background-color: #A90007 !important;
    border-color: #A90007 !important;
    color: #fff !important;
}

.intro {margin-bottom: 36px;
text-align: center;}

.intro p {
    max-width: 900px;
}
.intro h4{
    color: #A90007;
    color: var(--brand);
    font-weight: 400;
    text-transform: uppercase;
}

/*.intro h1 {
    margin-top: 15px;
    margin-bottom: 15px;
}*/

.info-box {
    align-items: center;
    display: flex;
}

.info-box img {
    width: 90px;
}


#milestone {
    background: linear-gradient(rgba(255, 77, 41, 0.85), rgba(255, 77, 41, 0.85)), url(/static/media/bg_banner1.1ffbdd4c.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#milestone h1,
#milestone p {
    color: #fff;
}

.service {
    padding: 32px;
    background-color: #fff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    box-shadow: var(--shadow);
}

.service h3 {
    margin-top: 15px;
    margin-bottom: 14px;
}


.project {
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.project h6 {
    font-weight: 400;
}

.project h6::before {
    content: "";
    height: 2px;
    width: 30px;
    display: inline-block;
    background: #A90007;
    background: var(--brand);
    vertical-align: middle;
    margin-right: 10px;
}


.project .overlay {
    width: 100%;
    height: 220px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 76, 41, 0) 0%, #092032 100%);
    background: linear-gradient(180deg, rgba(255, 76, 41, 0) 0%, var(--dark) 100%);
}

.project .content {
    position: absolute;
    left: 10%;
    bottom: 10%;
    height: 220px;
}

.project h2,
.project h6 {
    color: #fff;
}

.team-member {
    text-align: center;
}

.team-member .image{
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.team-member .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -10%;
    background-color: rgba(255, 77, 41, 0.7);
    opacity: 0;
    transition: all 0.4s ease;
}

.team-member h5 {
    margin-top: 16px;
    margin-bottom: 4px;
}

.team-member .social-icons {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: all 0.4s ease;
}

.team-member .social-icons a {
    width: 40px;
    height: 40px;
}

.team-member:hover .social-icons {
    top: 50%;
    opacity: 1;
}

.team-member:hover .overlay {
    top: 0%;
    opacity: 1;
}

#reviews {

    background: linear-gradient(-90deg, rgba(8, 32, 50, 0.8), rgba(8, 32, 50, 0.8)), url(/static/media/bg_banner1.1ffbdd4c.jpg), #082032;;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.review {
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 15px;
    max-width: 768px;
    margin: auto;
}

.review .bxs-quote-alt-left {
    font-size: 120px;
    position: absolute;
    opacity: 0.1;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
}

.review img {
    width: 80px !important;
    height: 80px;
    border-radius: 100px;
    margin: auto;
}

.review h5 {
    margin-top: 16px;
    margin-bottom: 4px;
    color: #fff;
}

.review h3 {
    margin-top: 26px;
    margin-bottom: 26px;
    font-size: 22px;
    color: #fff;
    font-weight: 400;
    line-height: 1.7;
}

.review small {
    color: #A90007;
    color: var(--brand);
}

.review .stars {
    color: #A90007;
    color: var(--brand);
}

.blog-post {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    box-shadow: var(--shadow);
}

.blog-post .content {
    padding: 32px;
}

.blog-post a {
    
    background-color: #A90007;
    
    background-color: var(--brand);
    padding: 2px 12px;
    border-radius: 100px;
    text-decoration: none;
    color: #fff;
}

.blog-post a:hover {
       
    color: #fff !important;
    font-weight: bolder !important;
}

.blog-post h5 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.blog-post small {
    text-transform: uppercase;
    color: #A90007;
    color: var(--brand);
    text-decoration: underline;
}

footer {
    /* background: linear-gradient(0deg, rgba(8, 32, 50, 0.9), rgba(8, 32, 50, 0.9)), url(./Assets/Images/project4.jpg), #082032;; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color:#082032 ; */
    background-color: #082032 !important;
    background-color: var(--footercolor) !important;
}

footer .footer-top {
    padding-top: 20px;
    padding-bottom: 60px;
}

.footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px;
    padding-top: 20px;
}

footer .navbar-brand {
    color: #fff;
}

footer p {
    color: #ADB3B9;
}

footer .social-icons a {
    width: 50px;
    height: 50px;
    font-size: 20px;
    margin-left: 4px;
    margin-right: 4px;
}

.loader {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    z-index: 99999;
    position: fixed;
    left: 0;
    right: 0;
}


input.form-control {
    border-color: transparent;
    height: 44px;
}

.form-control {
    background-color: rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.04);;
}

.form-control:focus {
    box-shadow: none;
    border-color: #A90007;
    border-color: var(--brand);
}
.markImg
{
    margin-right: auto !important;
    margin-left: auto !important;
    padding-top: 50px !important;
    display: block !important;
    max-width: 50px !important; max-height: 100px !important;text-decoration: none !important;
}
 
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer !important;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
  cursor: pointer !important;
}
 

::-webkit-scrollbar-thumb {
  background: #DC3545; 
  border-radius: 5px;
  cursor: pointer !important;
}


::-webkit-scrollbar-thumb:hover {
  background: #DC3545; 
  cursor: pointer !important;
} 

#bckrnd
{
  background-image: url(/static/media/c2.7c66f6f1.jpg);
  min-height:300px;
}
#lglogos
{
  width: 150px !important; margin-left: auto !important; margin-right: auto !important; display: block !important;
}
#mlct
{
  font-size: 13px !important;
}
.h1d
{
  font-size: 30px !important;
}

.cetas-button{ background-color: #DC3545 !important;
    border-color: #DC3545 !important;
    color: #fff;
    border-radius: 5;
    
}
body .back-section {
  margin-bottom: 20px;
}
body .back-section a {
  color: #41A2FF !important;
  font-size: 20px !important;
  cursor: pointer;
}
body .back-section a img {
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
  font-weight: bolder !important;
  cursor: pointer;
  
}

body .back-section Button {
  cursor: pointer;
   float: right;
   background-color: #1063C7;
   width: 15%;
   align-self: center;
   font-size: 15px !important;
   border: none;
   padding-top: 5px !important;
   padding-bottom: 5px !important; 
}

body .back-section Button:hover
{
   background-color: #40DDA2 !important;
   border: none;
   font-weight: bold !important;
}




.User {
  text-align: center;
  /* background-color:#118160 !important; */
 
 
}

.showCartItem
{
 text-align: center !important;
  height: auto !important;
  padding-top: 200px !important;
  padding-bottom: 200px !important;
  /* min-height: 450px !important; */
 /* border-color: #40DDA2 !important; */
 /* word-break: break-all !important; */
 
}


.nav-cart
{
   width: 40px !important;
   height: 30px !important;
   margin-top: 5px !important;
   /* padding: 5px !important; */
}

.Export_word
{
 
 width:100% !important;
 /* display:inline-block !important; */
 height: auto;
 min-height: 30px !important;
 float: left;
 /* display:block; */
 
}

/* table {
  border-collapse: collapse;
}

td {
  border: 1px rgb(9, 9, 9) solid;
  padding: 4px;
  width:auto !important;
  min-width: 10em !important;
  font-size: 12px;
  text-align: left !important;
} */

/* th {
  border: 1px rgb(9, 9, 9) solid;
  padding: 4px;
  width:auto !important;
  min-width: 10em !important;
  font-size: 12px;
  font-weight: bold;
  text-align: left !important;
} */

.Export_word_question_type
{
 color: rgb(255, 55, 0) !important;
 font-weight: bold;
 
}
.Export_word_instruction
{
 font-style: italic;
 
}


.Export_word_R
{
 text-align: left !important;
  width:90% !important;
  /* display: inline-block !important; */
  display: block;
  float: left;
  height: auto;
  /* min-height: 80px !important; */
  /* position:relative !important; */
  
}

.grid_colomn_style1
{
 text-align: center !important;
 width:10% !important;
 /* border-color: #40DDA2 !important; */
 /* word-break: break-all !important; */
 
 
}

.Question_H
 {
  width:50% !important;
  text-align: center !important;
 }
 
.Question_
 {
  width:50% !important;
  text-align: justify !important;
 }

 .check_
 {
  width:5% !important;
 }


.button_style
{
 background-color: #1063C7;
 width: 15%;
 align-self: center;
 font-size: 15px !important;
 cursor: pointer;
 
 border: none;
 padding-top: 15px !important;
 padding-bottom: 15px !important;
}

.button_style:hover
{
   background-color: #40DDA2 !important;
   border: none;
   font-weight: bold !important;
}

.topic {
  width: 100%;
  align-items: left;
  justify-content: left;
  font-size: calc(14px );
  
}


.question-row {
  background-color:beige !important;
  min-width: 120px !important;
  max-width: 250px !important;
  min-height: 5vh;
  width: auto;  
  align-items: left;
  justify-content: left;
  font-size: calc(14px );
   /* border:solid;
   border-color: #000000;
   */
}

.question-row:hover {

  background-color: antiquewhite;
}


.question-row-column {
 
  width: auto;
   min-width: 120px !important;
   max-width: 250px !important;
  align-items: left;
  justify-content: left;
  font-size: calc(14px );
  padding: 10px;
  border:solid;
  border-color:#c1bcbc;
  
}












.User-header {
  /* background-color: #F9F9F9; */
  /* background-color:#118160 !important; */
  min-height: 11vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 72px !important;
  
  
}
.pg-content {
   /* background-color:#EEEDED; */
   background-color: #D4EDFC;
   padding-bottom: 3%;
  
  
}

.reset_button {
  cursor: pointer;
   background-color: #1063C7;
   align-self: center;
   font-size: 15px !important;
   border: none;
   padding-top: 7px !important;
   padding-bottom: 7px !important;
   padding-left: 10px;
   padding-right: 10px;
}

.reset_button:hover
{
   background-color:orange !important;
   border: none;
   font-weight: bold !important;
}


.showcartitem {
  background-color:#fff;
  padding-bottom: 3%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  border-radius: 10px;
 
}

.navbar
{
  background-color: #FFFFFF;
  min-height: 14vh !important;
}
.navbar-brand
{
 
  padding-left: 0px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.navbar-brand img
{
  position: fixed;
  margin-top: -30px;
  height: 63px;
  width: 160px;

}
.navbar-nav
{
  padding-right: 20px; 
  margin-top: -6px; 
}
.navbar-nav .nav-link
{
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 30px !important;  

  
}
.navbar-nav .login-link
{
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 30px !important;  
  
  
}
.edit-link
{
  font-size: 14px !important;
  font-weight: bold;
  margin-right: 2% !important; 
  align-self: flex-end;
  margin-top:.5%;
  cursor: pointer;
  
  position:absolute !important;
}

.navbar-nav img
{
 margin-left: -40px;
  width: 35px;
  height: 35px;
  margin-top: -8px;
  cursor: pointer;
  
}



 .navbar-nav button, .navbar-nav button:hover
{
  color: white !important;
  background-color: #DC3545 !important;
 height: 40px;
 margin-top: 10px;
}
.navbar-nav button:hover
{
  color: white !important;
  background-color: #DC3545 !important;
  
}




#mainDashboard
{
  padding-top: 15px !important;
  color: black !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  
  filter: blur(0.1px);
  -webkit-filter: blur(0.1px);
}
table thead
{
  font-size: 16px !important;
}
table tbody
{
  font-size: 16px !important;
  color: #000000 !important;
}
/* td
{
  margin-left: auto;
  margin-right: auto;
} */

td
{
  /* margin-left: auto;
  margin-right: auto; */
  width: 10% !important;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center !important;
  /* background-color: #c1bcbc !important; */

}



.searching
{
  float: right !important;
  align-content: right !important;
  
}
.searching input[type="search"]
{
  border-bottom-color: #02365F !important;
  height: 40px !important;
  min-width: 200px !important;
  
}
.searching input, .searching input:hover, .searching input:active, .searching input:focus
{
  border-top: none !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #02365F !important;
  outline: none !important;
  outline-color: transparent !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  background-color: transparent !important;
}
.searching i
{
  color: #02365F !important;
  font-weight: lighter !important;
  font-size: 14px !important;
}
.card
{
  border-radius: 10px !important;
  border: none;
  /* background-color: #F4E9E9; */
}
.pieCard
{
  min-height: 70vh !important;
  max-height: auto !important;
}
.pCounts p
{
  padding-top: 20px !important;
  font-size: 15px !important;
}
.pCounts h1
{
  padding-top: 1px !important;
  font-size: 40px !important;
  position: fixed;
}
.pieHead
{
  font-size: 12px !important;
  text-align: center !important;
}
.ddetails
{
  min-height: 130px !important;
  max-height: 130px !important;
}
.ddetails h6
{
  position: fixed;
}
.hdet
{
  position: fixed;
  margin-top: -10px;
  padding-left: 10px;
  font-size: 50px;
  color: #02365F;
}
.pdet
{
  position: fixed;
  padding-left: 6%;
  padding-top: 15px;
  font-size: 20px;
}
.ddetails hr
{
  margin-top: 13%;
}
.breadcum p
{
  font-size: 15px !important;
  font-weight: bold;
  float: right;
  padding-right: 2%;
  
}
.breadcum p a
{
  text-decoration: none;
}
.breadcum h5, p
{
  padding-top: 2%;
}
.breadcum h5
{
  padding-left: 2%;
}
.kLogo
{
  border-radius: 50%;
  max-height: 120px;
  min-height: 120px;
  min-width: 120px;
  max-width: 120px;
}
.hexagon
{
  margin-top: 30px;
    width: 104px;
    height: 60px;
    background-color: #DC3545;
    border-color: #DC3545;
    position: relative;
    display: inline-block;
}
.hexagon::before {
  content: " ";
    width: 0; height: 0;
    border-bottom: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
    position: absolute;
    top: -30px;
}
.hexagon::after {
  content: "";
    width: 0;
    position: absolute;
    bottom: -30px;
    border-top: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}
.hexL
{
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  margin-top: auto;
  margin-left: auto;
  display: block;
  color: white;
  padding-left: 35%;
  padding-top: 15%;
}

tbody tr .ttdata
{
  padding-top: 5% !important;
  font-weight: bold;
  
}
*:focus {
    outline: 0 !important;
}
.mlselect, .mlselect:hover, .mlselect:active, .mlselect:focus
{
  border-top: none !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
