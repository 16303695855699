body .back-section {
  margin-bottom: 20px;
}
body .back-section a {
  color: #41A2FF !important;
  font-size: 20px !important;
  cursor: pointer;
}
body .back-section a img {
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
  font-weight: bolder !important;
  cursor: pointer;
  
}

body .back-section Button {
  cursor: pointer;
   float: right;
   background-color: #1063C7;
   width: 15%;
   align-self: center;
   font-size: 15px !important;
   border: none;
   padding-top: 5px !important;
   padding-bottom: 5px !important; 
}

body .back-section Button:hover
{
   background-color: #40DDA2 !important;
   border: none;
   font-weight: bold !important;
}




.User {
  text-align: center;
  /* background-color:#118160 !important; */
 
 
}

.showCartItem
{
 text-align: center !important;
  height: auto !important;
  padding-top: 200px !important;
  padding-bottom: 200px !important;
  /* min-height: 450px !important; */
 /* border-color: #40DDA2 !important; */
 /* word-break: break-all !important; */
 
}


.nav-cart
{
   width: 40px !important;
   height: 30px !important;
   margin-top: 5px !important;
   /* padding: 5px !important; */
}

.Export_word
{
 
 width:100% !important;
 /* display:inline-block !important; */
 height: auto;
 min-height: 30px !important;
 float: left;
 /* display:block; */
 
}

/* table {
  border-collapse: collapse;
}

td {
  border: 1px rgb(9, 9, 9) solid;
  padding: 4px;
  width:auto !important;
  min-width: 10em !important;
  font-size: 12px;
  text-align: left !important;
} */

/* th {
  border: 1px rgb(9, 9, 9) solid;
  padding: 4px;
  width:auto !important;
  min-width: 10em !important;
  font-size: 12px;
  font-weight: bold;
  text-align: left !important;
} */

.Export_word_question_type
{
 color: rgb(255, 55, 0) !important;
 font-weight: bold;
 
}
.Export_word_instruction
{
 font-style: italic;
 
}


.Export_word_R
{
 text-align: left !important;
  width:90% !important;
  /* display: inline-block !important; */
  display: block;
  float: left;
  height: auto;
  /* min-height: 80px !important; */
  /* position:relative !important; */
  
}

.grid_colomn_style1
{
 text-align: center !important;
 width:10% !important;
 /* border-color: #40DDA2 !important; */
 /* word-break: break-all !important; */
 
 
}

.Question_H
 {
  width:50% !important;
  text-align: center !important;
 }
 
.Question_
 {
  width:50% !important;
  text-align: justify !important;
 }

 .check_
 {
  width:5% !important;
 }


.button_style
{
 background-color: #1063C7;
 width: 15%;
 align-self: center;
 font-size: 15px !important;
 cursor: pointer;
 
 border: none;
 padding-top: 15px !important;
 padding-bottom: 15px !important;
}

.button_style:hover
{
   background-color: #40DDA2 !important;
   border: none;
   font-weight: bold !important;
}

.topic {
  width: 100%;
  align-items: left;
  justify-content: left;
  font-size: calc(14px );
  
}


.question-row {
  background-color:beige !important;
  min-width: 120px !important;
  max-width: 250px !important;
  min-height: 5vh;
  width: auto;  
  align-items: left;
  justify-content: left;
  font-size: calc(14px );
   /* border:solid;
   border-color: #000000;
   */
}

.question-row:hover {

  background-color: antiquewhite;
}


.question-row-column {
 
  width: auto;
   min-width: 120px !important;
   max-width: 250px !important;
  align-items: left;
  justify-content: left;
  font-size: calc(14px );
  padding: 10px;
  border:solid;
  border-color:#c1bcbc;
  
}












.User-header {
  /* background-color: #F9F9F9; */
  /* background-color:#118160 !important; */
  min-height: 11vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 72px !important;
  
  
}
.pg-content {
   /* background-color:#EEEDED; */
   background-color: #D4EDFC;
   padding-bottom: 3%;
  
  
}

.reset_button {
  cursor: pointer;
   background-color: #1063C7;
   align-self: center;
   font-size: 15px !important;
   border: none;
   padding-top: 7px !important;
   padding-bottom: 7px !important;
   padding-left: 10px;
   padding-right: 10px;
}

.reset_button:hover
{
   background-color:orange !important;
   border: none;
   font-weight: bold !important;
}


.showcartitem {
  background-color:#fff;
  padding-bottom: 3%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  border-radius: 10px;
 
}

.navbar
{
  background-color: #FFFFFF;
  min-height: 14vh !important;
}
.navbar-brand
{
 
  padding-left: 0px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.navbar-brand img
{
  position: fixed;
  margin-top: -30px;
  height: 63px;
  width: 160px;

}
.navbar-nav
{
  padding-right: 20px; 
  margin-top: -6px; 
}
.navbar-nav .nav-link
{
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 30px !important;  

  
}
.navbar-nav .login-link
{
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 30px !important;  
  
  
}
.edit-link
{
  font-size: 14px !important;
  font-weight: bold;
  margin-right: 2% !important; 
  align-self: flex-end;
  margin-top:.5%;
  cursor: pointer;
  
  position:absolute !important;
}

.navbar-nav img
{
 margin-left: -40px;
  width: 35px;
  height: 35px;
  margin-top: -8px;
  cursor: pointer;
  
}



 .navbar-nav button, .navbar-nav button:hover
{
  color: white !important;
  background-color: #DC3545 !important;
 height: 40px;
 margin-top: 10px;
}
.navbar-nav button:hover
{
  color: white !important;
  background-color: #DC3545 !important;
  
}




#mainDashboard
{
  padding-top: 15px !important;
  color: black !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  
  filter: blur(0.1px);
  -webkit-filter: blur(0.1px);
}
table thead
{
  font-size: 16px !important;
}
table tbody
{
  font-size: 16px !important;
  color: #000000 !important;
}
/* td
{
  margin-left: auto;
  margin-right: auto;
} */

td
{
  /* margin-left: auto;
  margin-right: auto; */
  width: 10% !important;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center !important;
  /* background-color: #c1bcbc !important; */

}



.searching
{
  float: right !important;
  align-content: right !important;
  
}
.searching input[type="search"]
{
  border-bottom-color: #02365F !important;
  height: 40px !important;
  min-width: 200px !important;
  
}
.searching input, .searching input:hover, .searching input:active, .searching input:focus
{
  border-top: none !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #02365F !important;
  outline: none !important;
  outline-color: transparent !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  background-color: transparent !important;
}
.searching i
{
  color: #02365F !important;
  font-weight: lighter !important;
  font-size: 14px !important;
}
.card
{
  border-radius: 10px !important;
  border: none;
  /* background-color: #F4E9E9; */
}
.pieCard
{
  min-height: 70vh !important;
  max-height: auto !important;
}
.pCounts p
{
  padding-top: 20px !important;
  font-size: 15px !important;
}
.pCounts h1
{
  padding-top: 1px !important;
  font-size: 40px !important;
  position: fixed;
}
.pieHead
{
  font-size: 12px !important;
  text-align: center !important;
}
.ddetails
{
  min-height: 130px !important;
  max-height: 130px !important;
}
.ddetails h6
{
  position: fixed;
}
.hdet
{
  position: fixed;
  margin-top: -10px;
  padding-left: 10px;
  font-size: 50px;
  color: #02365F;
}
.pdet
{
  position: fixed;
  padding-left: 6%;
  padding-top: 15px;
  font-size: 20px;
}
.ddetails hr
{
  margin-top: 13%;
}
.breadcum p
{
  font-size: 15px !important;
  font-weight: bold;
  float: right;
  padding-right: 2%;
  
}
.breadcum p a
{
  text-decoration: none;
}
.breadcum h5, p
{
  padding-top: 2%;
}
.breadcum h5
{
  padding-left: 2%;
}
.kLogo
{
  border-radius: 50%;
  max-height: 120px;
  min-height: 120px;
  min-width: 120px;
  max-width: 120px;
}
.hexagon
{
  margin-top: 30px;
    width: 104px;
    height: 60px;
    background-color: #DC3545;
    border-color: #DC3545;
    position: relative;
    display: inline-block;
}
.hexagon::before {
  content: " ";
    width: 0; height: 0;
    border-bottom: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
    position: absolute;
    top: -30px;
}
.hexagon::after {
  content: "";
    width: 0;
    position: absolute;
    bottom: -30px;
    border-top: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}
.hexL
{
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  margin-top: auto;
  margin-left: auto;
  display: block;
  color: white;
  padding-left: 35%;
  padding-top: 15%;
}

tbody tr .ttdata
{
  padding-top: 5% !important;
  font-weight: bold;
  
}
*:focus {
    outline: 0 !important;
}
.mlselect, .mlselect:hover, .mlselect:active, .mlselect:focus
{
  border-top: none !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}